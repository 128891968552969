import React from "react";
import { graphql } from "gatsby";
import { Button, Grid } from "semantic-ui-react";
import Helmet from "react-helmet";
import { PageLayout, HeroElement } from "@fishrmn/fishrmn-components";

import Layout from "../components/Layout";
import { getPageHeroImages } from "../utils/images";

export default class extends React.PureComponent {
  render() {
    const data = this.props.data.allFisherman.edges[0].node;
    const pageData = this.props.data.fishermanWebsitePage;
    const pageComponents = (pageData && pageData.components) || [];

    const ytUrls = [
      "https://www.youtube.com/embed/Rn_Tavxdci8?controls=0",
      "https://www.youtube.com/embed/q-zuA8wnvm8?controls=0?rel=0",
      "https://www.youtube.com/embed/DR9AZLFeYFA?controls=0?rel=0",
      "https://www.youtube.com/embed/ro6qSVCGwHo?controls=0?rel=0",
      "https://www.youtube.com/embed/1_vvm3h1OTo?controls=0?rel=0",
      "https://www.youtube.com/embed/HZOeKgVvMSg?controls=0?rel=0",
      "https://www.youtube.com/embed/n6-OTrLUkiE?controls=0?rel=0",
      "https://www.youtube.com/embed/xSojnCTecrw?controls=0?rel=0",
      "https://www.youtube.com/embed/srvpX5klyp4?controls=0?rel=0",
      "https://www.youtube.com/embed/mSfL9veIq3I?controls=0?rel=0",
      "https://www.youtube.com/embed/rOIfhqt9vGY?controls=0?rel=0",
      "https://www.youtube.com/embed/Z6RS-2dF4Ck?controls=0?rel=0",
      "https://www.youtube.com/embed/i_w78IeUmjk?controls=0?rel=0",
      "https://www.youtube.com/embed/926H7rd1R9k?controls=0?rel=0",
      "https://www.youtube.com/embed/oapVdqBlPcI?controls=0?rel=0",
      "https://www.youtube.com/embed/iguhrci9akI?controls=0?rel=0",
      "https://www.youtube.com/embed/haGjMXGLPaU?controls=0?rel=0",
      "https://www.youtube.com/embed/-jEU4kLIEWo?controls=0?rel=0",
      "https://www.youtube.com/embed/79c4UHI0pnE?controls=0?rel=0",
      "https://www.youtube.com/embed/xdU-gvMdf_k?controls=0?rel=0",
      "https://www.youtube.com/embed/HEe47IZrFds?controls=0?rel=0",
      "https://www.youtube.com/embed/EqKgzYpsJ20?controls=0?rel=0",
      "https://www.youtube.com/embed/pd82IRdVBgs?rel=0",
      "https://www.youtube.com/embed/Y1omcc4iGWw?rel=0",
      "https://www.youtube.com/embed/ticlLfJUngU?rel=0",
      "https://www.youtube.com/embed/vh0fx-9vKCk?rel=0",
      "https://www.youtube.com/embed/ZOo79UK0APQ?controls=0?rel=0",
      "https://www.youtube.com/embed/JrQ6BJcCGhE?controls=0?rel=0",
      "https://www.youtube.com/embed/Uj9veToHjGA?controls=0?rel=0",
      "https://www.youtube.com/embed/WJ3lGRDGOII?controls=0?rel=0",
      "https://www.youtube.com/embed/_a-bndksYjA?controls=0?rel=0",
      "https://www.youtube.com/embed/2ivyjO7abqM?controls=0?rel=0",
      "https://www.youtube.com/embed/tVxxM0T3kQE?controls=0?rel=0",
      "https://www.youtube.com/embed/jdTTNErrmUc?controls=0?rel=0",
      "https://www.youtube.com/embed/dHFQO-wUjpk?controls=0?rel=0",
      "https://www.youtube.com/embed/Ct_E6sMbaTU?controls=0?rel=0",
      "https://www.youtube.com/embed/QzsKyy9QIcg?controls=0?rel=0",
      "https://www.youtube.com/embed/CLN7eU8geNM?controls=0?rel=0",
      "https://www.youtube.com/embed/g3vdZ7--pHg?controls=0?rel=0",
      "https://www.youtube.com/embed/yc2T9ERYSVE?controls=0?rel=0",
      "https://www.youtube.com/embed/BP2LcEgp9tY?controls=0?rel=0",
      "https://www.youtube.com/embed/357r1J15JZ0?controls=0?rel=0",
      "https://www.youtube.com/embed/owiuEBedCdk?controls=0?rel=0",
      "https://www.youtube.com/embed/J2ALpT9tJxU?controls=0?rel=0",
      "https://www.youtube.com/embed/ERRrc3fT00w?controls=0?rel=0",
      "https://www.youtube.com/embed/NwlBFT3h_lA?controls=0?rel=0",
      "https://www.youtube.com/embed/mi_E7Xw_8Xo?rel=0",
      "https://www.youtube.com/embed/a6J989PE9Wg?controls=0?rel=0",
      "https://www.youtube.com/embed/TT6Egzj0adI?rel=0",
      "https://www.youtube.com/embed/owBD2HZzSWU?rel=0",
      "https://www.youtube.com/embed/VajS-oWbtBE?controls=0?rel=0",
      "https://www.youtube.com/embed/o1djkPSYaPM?controls=0?rel=0",
      "https://www.youtube.com/embed/_bH_uvlAGeU?controls=0?rel=0",
      "https://www.youtube.com/embed/NypEWjnTxt0?rel=0",
      "https://www.youtube.com/embed/5c-t1FeWH-c?rel=0",
      "https://www.youtube.com/embed/8l5Asi6oCZI?rel=0",
      "https://www.youtube.com/embed/MPd9HCGTWgs?rel=0",
      "https://www.youtube.com/embed/xykiD1Bg8LI?rel=0",
      "https://www.youtube.com/embed/X6c6AEIcZ1Y?rel=0",
      "https://www.youtube.com/embed/vsV_uMV-wvg?rel=0",
      "https://www.youtube.com/embed/mpSYb-nT1po?rel=0",
      "https://www.youtube.com/embed/96Nl4D0Xujk?rel=0",
      "https://www.youtube.com/embed/m-_MDeS2xwM?rel=0",
      "https://www.youtube.com/embed/0VVVu2TH_VY?rel=0",
      "https://www.youtube.com/embed/NcLnvwgz7J4?controls=0?rel=0"
    ];

    return (
      <Layout>
        <Helmet>
          <title>{data.businessName} | Videos</title>
          <meta name="description" content={pageData.description} />
        </Helmet>
        <PageLayout
        // hero={
        //   <HeroElement
        //     header={"In the News"}
        //     tagline={""}
        //     images={getPageHeroImages(pageComponents)}
        //     showMap={false}
        //     ctas={[
        //       {
        //         link: "https://fisherman.gumlet.io/public/opheliasbluevine/Ohhelias_Blue_Vine_2021.pdf",
        //         text: "News",
        //         internal: false,
        //       },
        //     ]}
        //     ctaInverted={false}
        //     ctaColor={"primary"}
        //     containerColor={"primary"}
        //     textColor={"white"}
        //     showMultiple={true}
        //     overlay={true}
        //     fullWidth={true}
        //     parallax={false}
        //     containerAs={"none"}
        //     height={500}
        //     gutter={false}
        //   />
        // }
        >
          <Grid
            stackable
            columns={3}
            className="video-container"
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            {ytUrls.map((url) => (
              <Grid.Column>
                <iframe
                  width="90%"
                  height="300"
                  src={url}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </Grid.Column>
            ))}
          </Grid>

          <Grid
            stackable
            verticalAlign={"middle"}
            textAlign={"center"}
            style={{ padding: "5em 3em" }}
          >
            <Grid.Column width={16}>
              <Button
                color="red"
                size="big"
                as="a"
                href="https://fisherman.gumlet.io/public/opheliasbluevine/Ohhelias_Blue_Vine_2021.pdf"
                target="_blank"
              >
                News
              </Button>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allFisherman {
      edges {
        node {
          businessName
          aboutMarkdown
          phone
          email
        }
      }
    }
    fishermanWebsitePage(pageType: { eq: "Home" }) {
      description
      components {
        fastId
        order
        files {
          file
        }
      }
    }
  }
`;
